<template>
    <div v-if="question" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ question.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <!-- <v-col cols="auto">
                            <span>문의유형 : {{ question.type }}</span>
                        </v-col> -->
                        <v-col cols="auto">
                            <span>{{$t("common.date_created")}} : {{ question.createdAt.toDate() }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr v-if="question.content">
                <td colspan="2" class="content_box">
                    <div v-html="question.content.replace(/\n/g,'<br>')"></div>
                </td>
            </tr>
            <tr v-if="question.reply">
                <td colspan="2" class="content_box">
                    <div v-html="question.reply.replace(/\n/g,'<br>')"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="list" large color="primary">{{$t("common.list")}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: ["_id"],
    data(){
        return {
            question: null
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { question } = await api.v1.center.questions.get({ _id: this.$props._id });
            this.question = question;
		},

        list(){
            this.$router.go(-1);
        },
	},
}
</script>
