var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-input"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("문의유형")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('select', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.question.type,
      expression: "question.type"
    }],
    staticClass: "select",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.question, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v("문의유형")]), _vm._l(_vm.questionTypes, function (type, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": type
      }
    }, [_vm._v(_vm._s(type))]);
  })], 2), _c('v-text-field', {
    attrs: {
      "placeholder": _vm.$t('common.title'),
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("내용")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  })], 1)])]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("수정")]), _c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("삭제")]), _c('v-btn', {
    staticClass: "grey-2--text",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("답글")])], 1), _c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-da"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t('common.취소')))])]), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t('common.confirm')))])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("일대일 문의")])]);

}]

export { render, staticRenderFns }