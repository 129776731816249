<template>
    <div class="board-input">
        <div class="mb-10"><h3 class="tit tit--xs">일대일 문의</h3></div>
        <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">문의유형</div>
                <div class="table-style__td">
                    <select v-show="false" v-model="question.type" class="select">
                        <option :value="null" disabled>문의유형</option>
                        <option v-for="(type, index) in questionTypes" :key="index" :value="type">{{ type }}</option>
                    </select>
                    <v-text-field v-model="question.subject" :placeholder="$t('common.title')" outlined hide-details></v-text-field>
                </div>
            </div>

            <div class="table-style__row">
                <div class="table-style__th">내용</div>
                <div class="table-style__td">
                    <v-textarea v-model="question.content" outlined hide-details></v-textarea>
                </div>
            </div>
        </div>

        <div class="board-bottom">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="cancel" large outlined color="grey-da"><span class="primary--text">{{$t('common.취소')}}</span></v-btn>
                <v-btn @click="save" large color="primary">{{$t('common.confirm')}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
	components: {
	},
    data() {
        return {
            files: [{ name: "" },{ name: "" },{ name: "" }],
            fileName: "",
            question: {
                type: null,
                subject: null,
                content: null,
            },
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        }
    },
	methods : {
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
        cancel(){
            this.$router.go(-1);
        },
        validate(){
            try{
                var question = this.question;
                // if(!question.type) throw new Error("문의유형을 선택해주세요");
                if(!question.subject) throw new Error("문의제목을 입력해주세요");
                if(!question.content) throw new Error("문의내용을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                await api.v1.center.questions.post(this.question);
                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            }
        },
	},
}
</script>
